import React from 'react';
import { useQuery, gql } from '@apollo/client';

import MainLayout from '@hoc/layout';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import FaqItem from '../components/faq-item/faq-item';
import HandleLoading from '@hoc/handle-loading/handle-loading';
import Seo from '@components/seo/seo';
import HorizontalScrollWrapper from '@ui-components/horizontal-scroll-wrapper';

import * as s from './faq.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import './faq.scss';
import { Link } from 'gatsby';

const PAGE_QUERY = gql`
    query FaqItemsQuery {
        faqs(first: 200) {
			nodes {
				content
				title
				faq {
					category
				}
			}
		}
    }
`;

const NajczesciejZadawanePytania = () => {

	const { loading, error, data } = useQuery(PAGE_QUERY);

	const handleNavClick = (target) => {
		document.getElementById(target).scrollIntoView({behavior: 'smooth'});
	}

	return (
		<MainLayout>

			<Seo
				title='Najczęściej zadawane pytania'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
			/>

			<BreadCrumbs />

      		<section className={`${s.faq_section} ${s_wrapper.apply} ${s_wrapper.apply_height}`}>

        		<h1>Najczęściej zadawane pytania</h1>

				<div className={s.layout}>


					<nav className={s.nav}>

						<HorizontalScrollWrapper classes={s.horizontal_wrapper}>
							<div className={s.item} onClick={() => handleNavClick('leasing')}>
								<div>Leasing</div>
								<p>
									Czym jest leasing i kiedy warto go wybrać? Poznaj odpowiedzi na najczęściej zadawane pytania!
								</p>
							</div>

							<div className={s.item} onClick={() => handleNavClick('ubezpieczenie')}>
								<div>Ubezpieczenie</div>
								<p>
									Jak możemy Ci pomóc w ubezpieczeniu auta? Poznaj odpowiedzi na pytania o ubezpieczenie.
								</p>
							</div>

							<div className={s.item} onClick={() => handleNavClick('umowy')}>
								<div>Umowy</div>
								<p>
									Jak wygląda umowa? Co po jej podpisaniu? Poznaj odpowiedzi na te i wiele innych pytań!
								</p>
							</div>

							<div className={s.item} onClick={() => handleNavClick('kredyty')}>
								<div>Kredyty</div>
								<p>
									Uzyskanie kredytu - dowiedz się, jak wygląda krok po kroku, jakie warunki musisz spełniać i kiedy warto go wybrać.
								</p>
							</div>

							<div className={s.item} onClick={() => handleNavClick('gap')}>
								<div>GAP</div>
								<p>
									Typy ubezpieczenia GAP - zapoznaj się z najważniejszymi. Poznaj cały proces zdobywania polisy typu GAP!
								</p>
							</div>
						</HorizontalScrollWrapper>

					</nav>


					<div className={s.content}>

						<div className={s.faq_section} id='leasing'>

							<h2>Leasing</h2>

							<p className={s.main_info}>
								Czym jest leasing i kiedy warto go wybrać? Poznaj odpowiedzi na najczęściej zadawane pytania!
							</p>

							<div className={s.questions_wrapper}>

								<HandleLoading loading={loading} error={error}>

									{data?.faqs.nodes.map((el, id) => {
										if(el.faq.category === 'Leasing'){
											return (
												<FaqItem header={el.title} key={id}>
													<div class='faq_content' dangerouslySetInnerHTML={{ __html: el.content }}>
													</div>
												</FaqItem>
											)
										}
									})}

								</HandleLoading>

							</div>

						</div>


						<div className={s.faq_section} id='ubezpieczenie'>

							<h2>Ubezpieczenie</h2>

							<p className={s.main_info}>
								Jak możemy Ci pomóc w ubezpieczeniu auta? Poznaj odpowiedzi na pytania o ubezpieczenie.
							</p>

							<div className={s.questions_wrapper}>

								<HandleLoading loading={loading} error={error}>

									{data?.faqs.nodes.map((el, id) => {
										if(el.faq.category === 'Ubezpieczenie'){
											return (
												<FaqItem header={el.title} key={id}>
													<div class='faq_content' dangerouslySetInnerHTML={{ __html: el.content }}>
													</div>
												</FaqItem>
											)
										}
									})}

								</HandleLoading>

							</div>

						</div>


						<div className={s.faq_section} id='umowy'>

							<h2>Umowy</h2>

							<p className={s.main_info}>
								Jak wygląda umowa? Co po jej podpisaniu? Poznaj odpowiedzi na te i wiele innych pytań!
							</p>

							<div className={s.questions_wrapper}>

								<HandleLoading loading={loading} error={error}>

									{data?.faqs.nodes.map((el, id) => {
										if(el.faq.category === 'Umowy'){
											return (
												<FaqItem header={el.title} key={id}>
													<div class='faq_content' dangerouslySetInnerHTML={{ __html: el.content }}>
													</div>
												</FaqItem>
											)
										}
									})}

								</HandleLoading>

							</div>

						</div>


						<div className={s.faq_section} id='kredyty'>

							<h2>Kredyty</h2>

							<p className={s.main_info}>
								Uzyskanie kredytu - dowiedz się, jak wygląda krok po kroku, jakie warunki musisz spełniać i kiedy warto go wybrać.
							</p>

							<div className={s.questions_wrapper}>


								<HandleLoading loading={loading} error={error}>

									{data?.faqs.nodes.map((el, id) => {
										if(el.faq.category === 'Kredyty'){
											return (
												<FaqItem header={el.title} key={id}>
													<div class='faq_content' dangerouslySetInnerHTML={{ __html: el.content }}>
													</div>
												</FaqItem>
											)
										}
									})}

								</HandleLoading>

							</div>

						</div>


						<div className={s.faq_section} id='gap'>

							<h2>GAP</h2>

							<p className={s.main_info}>
								Typy ubezpieczenia GAP - zapoznaj się z najważniejszymi. Poznaj cały proces zdobywania polisy typu GAP!
							</p>

							<div className={s.questions_wrapper}>

								<HandleLoading loading={loading} error={error}>

									{data?.faqs.nodes.map((el, id) => {
										if(el.faq.category === 'GAP'){
											return (
												<FaqItem header={el.title} key={id}>
													<div class='faq_content' dangerouslySetInnerHTML={{ __html: el.content }}>
													</div>
												</FaqItem>
											)
										}
									})}

								</HandleLoading>

							</div>

						</div>



					</div>

				</div>


			</section>

			<ContactSection />
      		<MapSection />

		</MainLayout>
	)
};

export default NajczesciejZadawanePytania;
