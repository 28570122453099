import React, { useState } from 'react';


import * as s from './faq-item.module.scss';



const FaqItem = ({ header, children }) => {

    const [active, setactive] = useState(false);

    return (
        <div className={`${s.faq_item} ${active && s.active}`}>

            <div className={s.header} onClick={() => setactive(!active)}>
                <h3>{header}</h3>
            </div>

            <div className={s.content}>
                {children}
            </div>

        </div>
    )
};

export default FaqItem;
